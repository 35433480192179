export default {
	adds:{
		loan_state_4: "실패",
		loan_state_3: "상환 완료",
		loan_state_2: "지급됨",
		loan_state_1: "승인 대기 중",
		repay_state_2: "상환 완료",
		repay_state_1: "미상환",
		repay_create: "대출 기간",
		repay_state: "업무 상태",
		repay_times: "단계",
		repay_time: "상환 기간",
		repay_money: "총 상환액",
		repay_total: "대출 금액",
		repay_pay: "상환 기록",
		repay: "대출 기록",
		loan_pic4: "사진 신분증",
		loan_pic3: "은행 명세서",
		loan_pic2: "소득 증명",
		loan_pic1: "부동산 소유권 증명서",
		loan_pic: "신용 대출(사진이 선명하게 보이는지 확인하세요)",
		loan_pics: "신분증 양식",
		loan_pics_view: "보려면 클릭",
		loan_repay_method_1: "일시 원금 및 이자 상환" ,
		loan_repay: "상환 방법",
		loan_apr: "일일 이자율",
		loan_time: "대출 주기",
		loan_type_1: "일",
		loan_type_2: "Moon",
		loan_money: "대출 금액",
		loan: "학자금 대출",
		one: "계정 설정",
		two:"수금 설정",
		three: "아이디 설정",
		four: "사용자 피드백",
		five: "로그인 종료",
		verifySuccess: "인증 통과",
		verifyFailed: "인증되지 않음",
		verifyPass: "암호 확인",
		notice: "알림",
		logout_tip: "로그아웃하시겠습니까?",
		day: "낮",
	},
	types: [
		{ v: 5, name: '자산 관리 계좌' },
		{ v: 4, name: '익스트림 계약 계좌' },
		{ v: 3, name: '법정통화 계정' },
		{ v: 2, name: '계약 계정' },
		{ v: 1, name: '코인 계정' },
	],
	billTypes: [
		{v: 101, name:'충전'},
		{v: 102, name:'입금'},
		{v: 103, name:'동결'},
		{v: 104, name:'해동'},
		{v: 105, name:'추가'},
		{v: 106, name:'추가'},
		{v: 201, name:'현금인출 동결'},
		{v: 202, name:'공제'},
		{v: 203, name:'현금 인출 성공'},
		{v: 204, name:'현금 인출 실패'},
		{v: 205, name:'현금 인출 수수료'},
		{v: 206, name:'내보내기'},
		{v: 207, name:'전입'},
		{v: 208, name:'코인 내보내기'},
		{v: 209, name:'코인 전입'},
		{v: 301, name:'코인 수수료'},
		{v: 302, name:'계약 수익'},
		{v: 303, name:'계약 손실'},
		{v: 311, name:'구매 옵션'},
		{v: 312, name:'옵션 수익'},
		{v: 313, name:'옵션 반환'},
		{v: 314, name:'옵션 수수료'},
		{v: 315, name:'코인 매입 동결'},
		{v: 316, name:'코인 매입 공제'},
		{v: 317, name:'코인 매입 반환'},
		{v: 318, name:'코인 매입'},
		{v: 319, name:'코인 매입'},
		{v: 320, name:'코인 판매 동결'},
		{v: 321, name:'코인 매출 공제'},
		{v: 322, name:'코인 판매 반환'},
		{v: 323, name:'코인이 입금되었습니다'},
		{v: 324, name:'코인이 입금되었습니다'},
		{v: 325, name:'코인 수수료'},
		{v: 401, name:'광산 장비 가입'},
		{v: 402, name:'광산 반환'},
		{v: 403, name:'광산 기계 수입'},
		{v: 404, name:'광산 종료'},
		{v: 405, name:'광산 종료 수수료'},
		{v: 501, name:'블라인드 구매'},
		{v: 502, name:'블라인드 박스 수익'},
	],
	common: {
		confirm: "확인",
		cancel: "취소",
		submit: "제출",
		reset: "초기화",
		next: "다음 단계",
		review: "심사 중",
		copy_success: "복제 성공！",
		copy_error: "복제 실패！",
		more: "추가",
		success: "성공",
		fail: "실패",
		place_input: "내용을 입력하십시오.",
		form_empty: "기록 없음",
		download: "다운로드",
		history_record: "기록",
		closing_position: "창고 정리",
		tip: "힌트",
		forget: "암호를 잊었습니다.",
		all: "모두",
	},
	message: {
		cancel_order: "위임 취소 여부?",
		stop_out: "강제 창고 정리 여부?",
	},
	header: {
		nav1: "현금거래",
		nav2: "코인거래",
		nav3: "계약거래",
		nav4: "마이크로",
		nav5: "스테이킹",
		nav6: "IEO",
		nav7: "나의자산",
		setting_account: "계정 설정",
		setting_collection: "수금 설정",
		setting_authentication: "아이디 설정",
		setting_feedback: "사용자 피드백",
		logout: "로그인 종료",
	},
	footer: {
		tit1: "우리에 대해",
		tit2: "사용자 지원",
		tit3: "도움말 센터",
		tit4: "연락처",
		tit5: "온라인 고객 서비스",
		p1: "플랫폼 소개",
		p2: "프로젝트 공시",
		p3: "개인 정보 보호 정책",
		p4: "메일박스 등록 방법",
		p5: "어떻게 실명으로 인증합니까",
		p6: "거래 암호를 설정하는 방법",
		p7: "충전이란 무엇이고, 어떻게 충전하는가",
		p8: "동전 인출이란 무엇인가, 동전 인출은 어떻게 하는가",
		p9: "코인 거래 초보자 가이드",
	},
	assets: {
		asset_verview: "资产总览",
		asset_verview_conversion: "자산 개요 환산",
		account_assets: "계좌 자산",
		financial_records: "재무 기록",
		tab_trade: "코인 계좌",
		tab_legal: "법화 계좌",
		tab_lever: "계약 계정",
		tab_second: "초고속 계약 계정",
		tab_financial: "재테크 계좌",
		tab_transfer: "회전",
		recharge: "충전",
		withdraw: "동전 인출",
		currency_transfer: "화폐의 종류가 바뀌다",
		asset_transfer: "자산 이체",
		transfer_record: "레코드 스왑",
	},
	deposit: {
		tab_deposit: "DeFi",
		tab_record: "DeFi기록",
		reference_annualization: "참조 연화",
		state: "상태",
		term: "기간",
		day: "일",
		buy: "구매",
		min_amount: "최소 금액",
		daily: "일일 생산량",
		waiting_time: "대기 시간",
		time: "시간",
		everyday: "매일",
		escrow_funds: "위탁 자금",
		returnType: "만료 반환",
		early_withdrawal: "조기 인출",
		estimated_income: "예상 수입",
		available_assets: "가용 자산"
	},
	home: {
		download: "TXCOIN 다운로드",
		ios_download: "IOS다운로드",
		android_download: "안드로이드 다운로드",
		banner_p1: "빠른 투자 포트폴리오 구축-->",
		banner_p2: "빠른 시작",
		banner_p3: "암호화 포트폴리오",
		banner_p4: "TXCOIN은 암호화폐를 거래하는 가장 간단한 곳이다.지금 등록 및 사용 시작",
		view_more: "자세히 보기",
		p1: "세계 최고의 디지털 자산 거래 플랫폼",
		p2: "전 세계 130개국 이상의 수백만 명의 사용자에게 안전하고 신뢰할 수 있는 디지털 자산 거래 및 자산 관리 서비스 제공",
		p3: "보안 신뢰성",
		p4: " 연간 디지털 자산 금융 서비스 경험",
		p5: "전문 분산 아키텍처 및 DDoS 공격 방지 시스템",
		p6: "글로벌 생태 배치",
		p7: "50 여러 나라에 현지화 거래 서비스 센터를 설립하다.",
		p8: "멀티 비즈니스 형태가 일체화된 블록체인 생태계 조성",
		p9: "사용자 우선 순위",
		p10: "선행 배상 메커니즘을 세우다.",
		p11: "투자자 보호 기금을 설립하다.",
		p12: "디지털 화폐 여행 지금 시작",
		p13: "가장 편리한 디지털 화폐 거래 방법 제공",
		p14: "언제 어디서나 멀티플랫폼 트랜잭션 가능",
		p15: "iOS, Android, Windows의 여러 플랫폼을 포괄하고 모든 비즈니스 기능을 지원합니다.",
	},
	ieo: {
		tab_iep: "DeFi",
		tab_record: "DeFi기록",
		label_issue_price: "발행 가격",
		label_number: "청약 수량",
		label_money: "합계 지불",
		label_time: "청약 시간",
	},
	legal: {
		banner_p1: "C2C:디지털 화폐를 신속하게 매매하다.",
		banner_p2: "C2C는 사용자가 C2C에서 직접 사업자와 거래할 수 있는 포인트 투 포인트 거래 플랫폼입니다.",
		banner_p3: "원하는 가격과 지불 방식으로 비트코인과 기타 디지털 화폐를 매매하다。",
		table_legal:"거래 홀",
		table_order:"코인 주문",
		buy: "구매",
		sell: "판매",
		steps_title: "3단계 P2P 거래 완료, 수수료 없이 코인 사기！",
		steps_tit1: "판매자 선택",
		steps_tit2: "판매자에게 수금 / 지불",
		steps_tit3: "암호화폐 발급 / 수령",
		steps_p1: "고객의 취향에 맞는 가격과 결제 방법을 선택하는 광고。",
		steps_p2: "구매 / 판매 수량 및 거래 금액을 입력하여 주문 완료。",
		steps_p3: "제안된 지불 방식으로 판매자에게 수금 / 지불。",
		steps_p4: "법화 거래가 완료되면 TXCOIN P2P에서 클릭",
		steps_p5: "[이미 이체, 판매자에게 알림].TXCOIN은 요금이 부과되지 않습니다.。",
		steps_p6: "판매자는 돈을 받은 후에 화폐를 발급할 것이다.법화 계정으로 가서 보기",
		steps_p7: "받으신 화폐입니다.수금 확인 후 TXCOIN P2P",
		steps_p8: "구매자에게 암호화폐 전달。",
	},
	lever: {
		market_trand: "시가 거래",
		limit_trand: "가격 제한 거래",
		tip: "1손은 1BTC",
		handicap: "접시",
		whole_transaction: "전역 거래",
		tab1: "현재 보유",
		tab2: "모두 보유",
		tab3: "모두 접수",
		tab4: "모두 창고 정리",
		tab5: "모두 취소",
		tab6: "원클릭 창고 정리",
		close_position: "창고 정리",
		position1: "모두 창고 정리",
		position2: "단지 여러 가지로",
		position3: "청구서만",
		positionState1: "창고 개설을 기다리다",
		positionState2: "창고 정리 대기 중",
		positionState3: "완료됨",
	},
	login: {
		tip1: "로그인 시작 TXCOIN",
		tip2: "언제 어디서나 거래 가능",
		tip3: "TXCOIN에 오신 것을 환영합니다.",
		tip4: "맥스오케이에 오신 걸 환영합니다.",
	},
	second: {
		transfer_model: "거래 모델",
		open_number: "창고 개설 수량",
		open_time: "창고 개설 시간",
		second_account: "초 계약 계정",
		profit_rate: "수익률",
		buy_long: "매입",
		buy_short: "매수하다",
		transfering: "거래 중",
		closed_position: "창고 정리됨",
	},
	trade: {
		transfer_amount: "거래액",
		buy: "매입",
		sell: "매도",
		now_entrust: "현재 위임",
		all_entrust: "모두 위임",
		history_entrust: "역사적 의뢰",
	},
	personal: {
			tit: "프로파일",
			tit1: "개인",
			tit2: "보안 수준",
			state1: "강하다",
			nav1: "메일박스 인증",
			nav2: "내 초대 코드",
			nav3: "로그인 암호",
			nav4: "거래 암호",
			nav5: "수금 설정",
			nav6: "인증",
			nav7: "사용자 피드백",
			modify: "수정",
			goSetting: "설정",
			login_psd: "로그인 암호 수정",
			trans_psd: "거래 암호 수정",
			collection: "수금 설정",
			authentication: "인증",
			feedback: "사용자 피드백"
	},
	form: {
		label_oldPsd: "이전 암호",
		place_oldPsd: "이전 암호를 입력하십시오.",
		label_newPsd: "새 암호",
		place_newPsd: "새 암호를 입력하십시오.",
		label_crmPsd: "암호 확인",
		place_crmPsd: "새 암호를 다시 입력하십시오.",
		label_bankType: "지불 방식",
		place_bankType: "수금 방식을 선택하세요",
		label_bank: "은행카드",
		label_realName:"실명",
		palce_realName:"실제 이름을 입력하십시오.",
		label_bankName:"구좌 개설 은행",
		palce_bankName:"계좌 개설 줄을 입력하십시오.",
		label_bankBranch:"계좌 개설 지점",
		palce_bankBranch:"계좌 개설 지점을 입력하십시오.",
		label_bankNumber:"은행카드 번호",
		palce_bankNumber:"은행카드 번호를 입력하십시오.",
		label_cardNumber: "증명서 번호",
		place_cardNumber: "증명서 번호를 입력하십시오.",
		card_img1: "신분증 업로드 첫 페이지를 클릭",
		card_img2: "증명서 업로드 페이지 클릭",
		card_img_tip:"귀하가 제출한 정보는 암호화되어 격리되어 저장되며, 제출한 정보는 시스템이 자동으로 심사하므로 안심하고 기입하시기 바랍니다.",
		label_feedbackType: "문제 유형",
		place_feedbackType: "질문 유형 선택",
		feedbackType0: "인출 관련",
		feedbackType1: "블록체인 관련",
		label_feedbackTitle: "제목",
		place_feedbackTitle: "제목을 입력하십시오.",
		label_feedbackCont: "설명",
		place_feedbackCont: "설명을 입력하십시오.",
		place_currency: "코인을 선택하세요",
		place_account: "계정을 선택하십시오.",
		label_acrrency: "화폐 종류",
		label_number: "수량",
		label_record: "기록",
		label_time: "시간",
		label_available: "사용 가능",
		label_freeze: "동결",
		label_total: "환산",
		label_operate: "작업",
		label_transfer_currency: "화폐 종류를 이체하다.",
		label_transfer_account: "계좌를 이체하다",
		label_account_balance: "계좌 잔액",
		label_transfer_number: "회전 수량",
		place_transfer_number: "회전 수량을 입력하십시오.",
		label_buy_price: "매입가",
		label_buy_number: "매입량",
		place_buy_market: "시장에서 가장 좋은 가격으로 매입하다.",
		label_sell_price: "매도 가격",
		label_sell_number: "매도량",
		place_sell_market: "시장에서 가장 좋은 가격으로 팔다.",
		product_name: "제품 이름",
		buy_total: "총 구매 수량",
		earnings: "누적 이익",
		product_cycle: "제품 주기",
		state: "상태",
		time: "시간",
		place_phone_email: "핸드폰 메일박스",
		login: "로그인",
		register: "등록",
		label_price: "가격",
		place_price: "가격을 입력하십시오.",
		label_multiple: "배수",
		place_multiple: "배수를 선택하십시오.",
		label_hand: "손수",
		place_hand: "손 수를 입력하십시오.",
		label_volume: "24H재다",
		daily_fluctuations: "일 등락",
		label_quotes: "근 사흘",
		label_toTrade: "거래하러 가다",
		available_amount: "가용액",
		label_transfer_psd: "거래 암호",
		palce_transfer_psd: "거래 암호를 입력하십시오.",
		buy_long: "많이 사다",
		sell_short: "공매",
		elail_login: "메일박스 로그인",
		phone_login: "휴대폰 로그인",
		label_email: "메일박스",
		place_email: "메일박스 입력",
		label_phone: "핸드폰 번호",
		place_phone: "핸드폰 번호를 입력하세요.",
		label_password: "암호",
		place_password: "암호를 입력하십시오.",
		remember_psd: "비밀번호 기억하기",
		no_account: "아직 계정이 없습니다.？",
		forget_psd: "암호를 잊었습니다.？",
		label_verify: "인증 코드",
		place_Verify: "인증 코드를 입력하십시오.",
		send: "발송",
		label_invite: "초대 코드",
		place_invite: "초대 코드를 입력하십시오.",
		have_account: "계좌번호 있어요？",
		go_login: "로그인",
		label_linkName: "체인 이름",
		label_recharge_address: "동전 인출 주소",
		place_recharge_address: "동전 인출 주소를 입력하십시오.",
		label_recharge_number: "충전 수량",
		place_recharge_number: "충전 수량을 입력하세요",
		label_wallet_address: "지갑 주소",
		place_wallet_address: "지갑 주소를 입력하십시오.",
		label_withdraw_number: "인출 수량",
		place_withdraw_number: "코인 인출 수량을 입력하세요",
		place_min_withdraw: "최소 인출 수량",
		label_remark: "설명",
		place_remark: "설명을 입력하십시오.",
		label_transPsd: "거래 암호",
		place_transPsd: "거래 암호를 입력하십시오.",
		message1: "금액을 입력하세요",
		message2: "계좌 잔액 부족",
		label_amount: "금액",
		place_amount: "금액을 입력하세요",
	},
	table: {
		label_time: "시간",
		label_title: "거래 쌍",
		label_direction: "방향",
		label_price: "가격",
		label_number: "수량",
		label_volume: "거래량",
		label_operate: "작업",
		label_acrrency: "화폐 종류",
		label_last_price: "최신 가격",
		label_ncrease: "상승폭",
		label_height_price: "최고가",
		label_low_price: "최저가",
		label_quantity: "24H 양",
		label_buyPrice: "구매 가격",
		label_finalPrice: "거래 가격",
		label_expectedProfit: "예상 손익",
		label_countDown: "카운트다운",
		sell: "팔다",
		buy: "사다",
		label_type: "유형",
		label_open_price: "창고 개설 가격",
		label_now_price: "현재 가격",
		label_bail: "보증금",
		label_stop_profit: "이윤 정지 가격",
		label_stop_loss: "손실 방지 가격",
		label_open_time: "창고 개설 시간",
		label_fee: "수수료",
		label_overnight_fee: "하룻밤 요금",
		label_profit_loss: "손익",
		label_total_amount: "총 금액",
		label_order_time: "주문 시간",
		label_order_income: "주문 수익",
		label_state: "상태",
		label_endTime: "종료 시간",
		state0: "보류 중",
		state1: "완료됨",
	},
	params: {
		recordType1: "지출",
		recordType2: "충전",
		ieoState0: "모두",
		ieoState1: "예매 중",
		ieoState2: "청약 중",
		ieoState3: "종료",
		depositState0: "뜨겁게 진행 중입니다.",
		depositState1: "기대해주세요.",
		lockStateType0: "실행 중",
		lockStateType1: "종료",
		lockStateType2: "취소됨",
	},
    city: {
        albania: "알바니아",
        algeria: "알제리",
        argentina: "아르헨티나",
        armenia: "아르메니아",
        australia: "호주",
        pakistan: "파키스탄",
        austria: "오스트리아",
        bahrain: "바레인",
        belgium: "벨기에",
        bosnia_and_Herzegovina: "보스니아 헤르체고비나",
        brazil: "브라질",
        brunei: "브루나이",
        bulgaria: "불가리아",
        cambodia: "캄보디아",
        canada: "캐나다",
        cameroon: "카메룬",
        chile: "칠레",
        colombia: "콜롬비아",
        costa_Rica: "코스타리카",
        croatia: "크로아티아",
        cyprus: "키프로스",
        czech_Republic: "체코",
        denmark: "덴마크",
        dominican_Republic: "도미니카",
        egypt: "이집트",
        estonia: "에스토니아",
        ethiopia: "에티오피아",
        finland: "핀란드",
        france: "프랑스",
        georgia: "그루지야",
        germany: "독일",
        ghana: "가나",
        greece: "그리스",
        guyana: "가이아나",
        honduras: "온두라스",
        hong_Kong_China: "중국 홍콩",
        hungary: "헝가리",
        iceland: "아이슬란드",
        ireland: "아일랜드",
        italy: "이탈리아",
        india: "인도",
        indonesia: "인도네시아",
        israel: "이스라엘",
        iran: "이란",
        iraq: "이라크",
        japan: "일본",
        kazakstan: "카자흐스탄",
        kenya: "케냐",
        korea: "한국",
        kuwait: "쿠웨이트",
        kyrgyzstan: "키르기스스탄",
        laos: "라오스",
        Latvia: "라트비아",
        lithuania: "리투아니아",
        luxembourg: "룩셈부르크",
        macao_China: "중국 마카오",
        macedonia: "마케도니아",
        malaysia: "말레이시아",
        malta: "몰타",
        mexico: "멕시코",
        moldova: "몰도바",
        monaco: "모나코",
        mongolia: "몽골",
        montenegro: "몬테네그로",
        morocco: "모로코",
        myanmar: "미얀마",
        netherlands: "네덜란드",
        new_Zealand: "뉴질랜드",
        nepal: "네팔",
        nigeria: "나이지리아",
        norway: "노르웨이",
        oman: "오만",
        palestine: "팔레스타인",
        panama: "파나마",
        paraguay: "파라과이",
        peru: "페루",
        philippines: "필리핀",
        poland: "폴란드",
        portugal: "포르투갈",
        puerto_Rico: "푸에르토리코",
        qatar: "카타르",
        romania: "루마니아",
        russia: "러시아",
        republic_of_Trinidad_and_Tobago: "트리니다드 토바고",
        rwanda: "르완다",
        saudi_Arabia: "사우디아라비아",
        serbia: "세르비아",
        singapore: "싱가포르",
        slovakia: "슬로바키아",
        slovenia: "슬로베니아",
        south_Africa: "남아프리카",
        spain: "스페인",
        sri_Lanka: "스리랑카",
        sweden: "스웨덴",
        switzerland: "스위스",
        taiwan_China: "대만",
        tajikistan: "타지키스탄",
        tanzania: "탄자니아",
        thailand: "태국",
        turkey: "터키",
        turkmenistan: "투르크메니스탄",
        ukraine: "영국",
        united_Arab_Emirates: "미국",
        united_Kingdom: "우즈베키스탄",
        united_States: "베네수엘라",
        uzbekistan: "베트남",
        venezuela: "아프가니스탄",
        vietnam: "베트남",
        afghanistan: "아프가니스탄",
        angola: "앙골라",
        azerbaijan: "아제르바이잔",
        bangladesh: "방글라데시",
        belarus: "벨라루스",
        belize: "벨리즈",
        benin: "베냉",
        bhutan: "부탄",
        bolivia: "볼리비아",
        botswana: "보츠와나",
        british_Virgin_Islands: "영국령 버진 아일랜드",
        burkina_Faso: "부르키나파소",
        burundi: "부룬디",
        cape_Verde: "카보베르데",
        cayman_Islands: "케이맨 제도",
        central_African_Republic: "중앙아프리카",
        chad: "차드",
        comoros: "코모로",
        the_Republic_of_the_Congo: "콩고 (부)",
        democratic_Republic_of_the_Congo: "콩고 (김)",
        djibouti: "지부티",
        ecuador: "에콰도르",
        el_Salvador: "엘살바도르",
        equatorial_Guinea: "적도 기니",
        eritrea: "에리트레아",
        fiji: "피지",
        gabon: "가봉",
        gambia: "감비아",
        greenland: "그린란드",
        guatemala: "과테말라",
        guinea: "기니",
        haiti: "아이티",
        isle_of_Man: "맨 섬",
        cote_d_Ivoire: "코트디부아르",
        jamaica: "자메이카",
        jordan: "요르단",
        lebanon: "레바논",
        lesotho: "레소토",
        liberia: "라이베리아",
        libya: "리비아",
        madagascar: "마다가스카르",
        malawi: "말라위",
        maldives: "몰디브",
        mali: "말리",
        mauritania: "모리타니",
        mauritius: "모리셔스",
        mozambique: "모잠비크",
        namibia: "나미비아",
        nicaragua: "니카라과",
        republic_of_Niger: "니제르",
        north_Korea: "조선",
        reunion: "레위니옹",
        san_Marino: "산마리노",
        senegal: "세네갈",
        sierra_Leone: "시에라리온",
        somalia: "소말리아",
        sudan: "수단",
        suriname: "수리남",
        eswatini: "스와질란드",
        syria: "시리아",
        togo: "토고",
        tonga: "통가",
        tunisia: "튀니지",
        united_States_Virgin_Islands: "미국령 버진 아일랜드",
        uganda: "우간다",
        uruguay: "우루과이",
        vatican: "바티칸",
        yemen: "예멘",
        yugoslavia: "유고슬라비아",
        zambia: "잠비아",
        zimbabwe: "짐바브웨",
        china: "중국",
      }
};
