export default {
	adds:{
		rechargePic: "Chứng từ chuyển khoản",
		loan_state_4: "Thất bại",
		loan_state_3: "Đã thanh toán",
		loan_state_2: "Đã phát hành",
		loan_state_1: "Đang được xem xét",
		repay_state_2: "Đã thanh toán",
		repay_state_1: "Chưa hoàn trả",
		repay_create: "Thời gian cho vay",
		repay_state: "Trạng thái",
		repay_times: "Số kỳ",
		repay_time: "Thời gian trả nợ",
		repay_money: "Tổng số tiền hoàn trả",
		repay_total: "Số tiền vay",
		repay_pay: "Hồ sơ trả nợ",
		repay: "Hồ sơ vay vốn",
		loan_pic4: "Hình ảnh ID",
		loan_pic3: "Hóa đơn ngân hàng",
		loan_pic2: "Bằng chứng thu nhập",
		loan_pic1: "Giấy chứng nhận tài sản",
		loan_pic: "Cho vay tín dụng (đảm bảo hình ảnh rõ ràng)",
		loan_pics: "Tài liệu chứng minh",
		loan_pics_view: "Click để xem",
		loan_repay_method_1: "Trả lãi một lần." ,
		loan_repay: "Phương thức thanh toán",
		loan_apr: "Lãi suất hàng ngày",
		loan_time: "Thời gian cho vay",
		loan_type_1: "Ngày",
		loan_type_2: "Tháng",
		loan_money: "Số tiền vay",
		loan: "Trợ giúp cho vay",
		one: "Thiết lập tài khoản",
		two: "Tài khoản ngân hàng",
		three: "Xác minh tài khoản",
		four: "Người dùng phản hồi",
		five: "Đăng xuất",
		verifySuccess: "Chứng nhận thông qua",
		verifyFailed: "Không xác nhận",
		verifyPass: "Xác nhận mật khẩu",
		notice: "Thay đổi",
		logout_tip: " Cài đặt",
		day: "Ngày",
	},
	types: [
		{ v: 5, name: 'Tài khoản tài chính' },
		{ v: 4, name: 'Tài khoản hợp đồng nhanh' },
		{ v: 3, name: 'Tài khoản fiat' },
		{ v: 2, name: 'Tài khoản hợp đồng' },
		{ v: 1, name: 'Tài khoản Coin' },
	],
	billTypes: [
		{v: 101, name: 'Nạp tiền'},
		{v: 102, name: 'Đăng ký'},
		{v: 103, name: 'Đóng băng'},
		{v: 104, name: 'Làm tan băng'},
		{v: 105, name: 'Đăng ký'},
		{v: 106, name: 'Đăng ký'},
		{v: 201, name: 'Rút tiền đóng băng'},
		{v: 202, name: 'Rút tiền'},
		{v: 203, name: 'Rút tiền thành công'},
		{v: 204, name: 'Rút tiềnThất bại'},
		{v: 205, name: 'Phí rút tiền'},
		{v: 206, name: 'Chuyển ra'},
		{v: 207, name: 'Chuyển tiếp'},
		{v: 208, name: 'Chuyển tiền xu'},
		{v: 209, name: 'Chuyển tiền.'},
		{v: 301, name: 'Phí xử lý tiền tệ'},
		{v: 302, name: 'Lợi nhuận hợp đồng'},
		{v: 303, name: 'Hợp đồng thua lỗ'},
		{v: 311, name: 'Tùy chọn mua'},
		{v: 312, name: 'Lợi nhuận tùy chọn'},
		{v: 313, name: 'Tùy chọn trả lại'},
		{v: 314, name: 'Phí xử lý quyền chọn'},
		{v: 315, name: 'Coin mua đóng băng'},
		{v: 316, name: 'Tiền xu mua vào khấu trừ'},
		{v: 317, name: 'Coin Mua Trở Lại'},
		{v: 318, name: 'Tiền mua vào tài khoản'},
		{v: 319, name: 'Tiền mua vào tài khoản'},
		{v: 320, name: 'Bán hàng đóng băng'},
		{v: 321, name: 'Bán đồng xu khấu trừ'},
		{v: 322, name: 'Bán coin trở lại'},
		{v: 323, name: 'Bán đồng xu vào tài khoản'},
		{v: 324, name: 'Bán đồng xu vào tài khoản'},
		{v: 325, name: 'Phí xử lý tiền tệ'},
		{v: 401, name: 'Miner tham gia'},
		{v: 402, name: 'Miner trở lại'},
		{v: 403, name: 'Thu nhập Miner'},
		{v: 404, name: 'Máy đào thoát'},
		{v: 405, name: 'Phí rút tiền máy đào'},
		{v: 501, name: 'Mua hộp mù'},
		{v: 502, name: 'Thu nhập hộp mù'},
	],
	common: {
		confirm: "Xác nhận",
		cancel: "Hủy bỏ",
		submit: "Xác nhận",
		reset: "Đặt lại",
		next: "Tiếp theo",
		review: "Đang xem xét",
		copy_success: "Sao chép thành công！",
		copy_error: "Sao chép Thất bại！",
		more: "Thêm",
		success: "Thành công",
		fail: "Thất bại",
		place_input: "Vui lòng nhập nội dung",
		form_empty: "Không có hồ sơ.",
		download: "Tải về",
		history_record: "Lịch sử",
		closing_position: "Đóng vị trí",
		tip: "Mẹo",
		forget: "Quên mật khẩu",
		all: "Tất cả",
	},
	message: {
		cancel_order: "Hủy bỏ toàn bộ ủy quyền?",
		stop_out: "Bắt buộc đóng vị trí?",
	},
	header: {
		nav1: "Giao dịch tiền tệ",
		nav2: "Giao dịch tiền tệ",
		nav3: "Hợp đồng tương lai",
		nav4: "Hợp đồng giây",
		nav5: "DeFi",
		nav6: "IEO",
		nav7: "Tài sản của tôi",
		setting_account: "Thiết lập tài khoản",
		setting_collection: "Cài đặt thu tiền",
		setting_authentication: "Thiết lập nhận dạng",
		setting_feedback: "Phản hồi người dùng",
		logout: "Đăng nhập",
	},
	footer: {
		tit1: "Về chúng tôi",
		tit2: "Hỗ trợ người dùng",
		tit3: "Trung tâm trợ giúp",
		tit4: "Liên hệ",
		tit5: "Hỗ trợ trực tuyến",
		p1: "Giới thiệu nền tảng",
		p2: "Công bố dự án",
		p3: "Chính sách bảo mật",
		p4: "Cách đăng ký hộp thư",
		p5: "Cách xác thực tên thật",
		p6: "Cách đặt mật khẩu giao dịch",
		p7: "Nạp tiền là gì và cách nạp tiền",
		p8: "Pickup coin là gì và làm thế nào để Pickup coin",
		p9: "Hướng dẫn mới cho giao dịch tiền tệ",
	},
	assets: {
		asset_verview: "Tổng quan tài sản",
		asset_verview_conversion: "Tổng quan tài sản",
		account_assets: "Tài sản tài khoản",
		financial_records: "Hồ sơ tài chính",
		tab_trade: "Tài khoản Coin",
		tab_legal: "Tài khoản fiat",
		tab_lever: "Tài khoản hợp đồng",
		tab_second: "Tài khoản hợp đồng nhanh",
		tab_financial: "Tài khoản tài chính",
		tab_transfer: "Gạch ngang",
		recharge: "Nạp tiền",
		withdraw: "Rút tiền",
		currency_transfer: "Chuyển tiền",
		asset_transfer: "Chuyển tài sản",
		transfer_record: "Ghi chép gạch ngang",
	},
	deposit: {
		tab_deposit: "DeFi",
		tab_record: "DeFi Hồ sơ",
		reference_annualization: "Năm tham chiếu",
		state: "Trạng thái",
		term: "Thời hạn",
		day: "Ngày",
		buy: "Mua hàng",
		min_amount: "Số tiền tối thiểu",
		daily: "Sản lượng hàng ngày",
		waiting_time: "Thời gian chờ",
		time: "Thời gian",
		everyday: "Mỗi ngày",
		escrow_funds: "Tiền ký quỹ",
		returnType: "Hết hạn trả về",
		early_withdrawal: "Rút tiền sớm",
		estimated_income: "Thu nhập dự kiến",
		available_assets: "Tài sản khả dụng"
	},
	home: {
		download: "TXCOIN Tải xuống",
		ios_download: "Tải về iOS",
		android_download: "Tải về Android",
		banner_p1: "Bắt đầu ngay danh mục đầu tư của bạn -->",
		banner_p2: "Khởi động nhanh ",
		banner_p3: "Danh mục đầu tư tiền điện tử",
		banner_p4: "TXCOIN là nơi dễ dàng nhất để mua và bán tiền điện tử. Đăng ký và sử dụng ngay",
		view_more: "Xem thêm",
		p1: "Nền tảng giao dịch tài sản kỹ thuật số hàng đầu thế giới",
		p2: "Cung cấp dịch vụ quản lý tài sản và giao dịch tài sản kỹ thuật số an toàn, đáng tin cậy cho hàng triệu người dùng tại hơn 130 quốc gia trên toàn thế giới",
		p3: "An toàn và đáng tin cậy",
		p4: " Năm kinh nghiệm dịch vụ tài chính tài sản kỹ thuật số",
		p5: "Kiến trúc phân tán chuyên nghiệp và hệ thống chống tấn công DDOS",
		p6: "Bố cục sinh thái toàn cầu",
		p7: "Hơn 50 quốc gia thành lập trung tâm dịch vụ giao dịch nội địa",
		p8: "Xây dựng một vòng sinh thái Blockchain đa hình thái nghiệp vụ như một",
		p9: "Người dùng trên hết",
		p10: "Xây dựng cơ chế bồi thường trước",
		p11: "Thành lập quỹ bảo vệ nhà đầu tư",
		p12: "Bắt đầu hành trình của bạn với tiền kỹ thuật số ngay bây giờ",
		p13: "Cung cấp cho bạn cách thuận tiện nhất để giao dịch tiền kỹ thuật số",
		p14: "Giao dịch thiết bị đầu cuối đa nền tảng mọi lúc, mọi nơi",
		p15: "Bao gồm nhiều nền tảng iOS, Android, Windows, hỗ trợ đầy đủ các chức năng kinh doanh",
	},
	ieo: {
		tab_iep: "DeFi",
		tab_record: "Bản ghi DeFi",
		label_issue_price: "Giá phát hành",
		label_number: "Số lượng subscribe",
		label_money: "Tổng thanh toán",
		label_time: "Thời gian đăng ký",
	},
	legal: {
		banner_p1: "C2C: Mua và bán tiền kỹ thuật số nhanh chóng",
		banner_p2: "C2C là một nền tảng giao dịch ngang hàng, nơi người dùng có thể giao dịch trực tiếp với người bán trên C2C",
		banner_p3: "Mua và bán BiTXCOIN và các loại tiền tệ kỹ thuật số khác với mức giá và phương thức thanh toán mong muốn。",
		table_legal: "Sàn giao dịch",
		table_order: "Lệnh MUA Coin",
		buy: "Mua hàng",
		sell: "Bán hàng",
		steps_title: "Ba bước để hoàn thành giao dịch P2P, không phí thủ tục để mua tiền!",
		steps_tit1: "Chọn thương gia của bạn",
		steps_tit2: "Thu tiền/thanh toán cho người bán",
		steps_tit3: "Phát hành/Nhận tiền điện tử",
		steps_p1: "Chọn giá phù hợp với sở thích của bạn cũng như phương thức thanh toán。",
		steps_p2: "Nhập số lượng mua/bán và số tiền đóng để hoàn thành đơn đặt hàng.",
		steps_p3: "Thu tiền/thanh toán cho người bán thông qua phương thức thanh toán được đề xuất.",
		steps_p4: "Nhấp vào TXCOIN P2P sau khi hoàn thành giao diện",
		steps_p5: "[Đã chuyển tiền, thông báo cho người bán] TXCOIN sẽ không bị tính phí.",
		steps_p6: "Tiền sẽ được phát hành khi người bán nhận được tiền. Kiểm tra tài khoản fiat",
		steps_p7: "tiền mà bạn nhận được. Sau khi nhận tiền, tại TXCOIN P2P",
		steps_p8: "Phát hành tiền điện tử cho người mua。",
	},
	lever: {
		market_trand: "Giao dịch thị trường",
		limit_trand: "Giới hạn giao dịch",
		tip: "1 lot bằng 1BTC",
		handicap: "Cổng",
		whole_transaction: "Toàn giao dịch",
		tab1: "Vị trí hiện tại",
		tab2: "Tất cả các kho",
		tab3: "Tất cả danh sách chờ",
		tab4: "Đóng tất cả các vị trí",
		tab5: "Rút tất cả",
		tab6: "Đóng vị trí bằng một cú nhấp chuột",
		close_position: "Xác định vị trí đóng",
		position1: "Đóng tất cả các vị trí",
		position2: "Chỉ dựa vào đa đơn",
		position3: "Chỉ có một mình.",
		positionState1: "Chờ mở kho",
		positionState2: "Vị trí chờ đóng",
		positionState3: "Đã hoàn thành",
	},
	login: {
		tip1: "Chào mừng đến với TXCOIN",
		tip2: "Giao dịch mọi lúc mọi nơi",
		tip3: "Chào mừng bạn đến với TXCOIN",
		tip4: "Chào mừng đến với TXCOIN",
	},
	second: {
		transfer_model: "Chế độ giao dịch",
		open_number: "Số lượng vị trí mở",
		open_time: "Thời gian hợp đồng",
		second_account: "Số hợp đồng giây",
		profit_rate: "Lợi nhuận",
		buy_long: "Mua tăng",
		buy_short: "Mua giảm",
		transfering: "Giao dịch",
		closed_position: "Đóng vị trí",
	},
	trade: {
		transfer_amount: "Khối lượng giao dịch",
		buy: "Mua vào",
		sell: "Bán ra",
		now_entrust: "Ủy nhiệm hiện tại",
		all_entrust: "Tất cả ủy thác",
		history_entrust: "Lịch sử ủy thác",
	},
	personal: {
		tit: "Thông tin cơ bản",
		tit1: "Cá nhân",
		tit2: "Lớp bảo mật",
		state1: "Mạnh mẽ",
		nav1: "Email đăng nhập",
		nav2: "Mã mời của tôi",
		nav3: "Mật khẩu đăng nhập",
		nav4: "Mật khẩu giao dịch",
		nav5: "Tài khoản ngân hàng",
		nav6: "Xác minh tài khoản",
		nav7: "Người dùng phản hồi",
		modify: "Cài đặt",
		goSetting: "Cài đặt",
		login_psd: "Thay đổi mật khẩu đăng nhập",
		trans_psd: "Mật khẩu giao dịch",
		collection: "Cài đặt thu tiền",
		authentication: "Chứng nhận",
		feedback: "Phản hồi người dùng"
	},
	form: {
		label_oldPsd: "Mật khẩu cũ",
		place_oldPsd: "Vui lòng nhập mật khẩu cũ",
		label_newPsd: "Mật khẩu mới",
		place_newPsd: "Vui lòng nhập mật khẩu mới",
		label_crmPsd: "Xác nhận Mật khẩu",
		place_crmPsd: "Vui lòng nhập lại mật khẩu mới",
		label_bankType: "Phương thức thanh toán",
		place_bankType: "Vui lòng chọn phương thức thu tiền",
		label_bank: "Tài khoản ngân hàng",
		label_realName:"Tên thật",
		palce_realName:"Vui lòng nhập tên thật",
		label_bankName:"Ngân hàng",
		palce_bankName:"Vui lòng điền tên ngân hàng",
		label_bankBranch:"Chi nhánh ngân hàng",
		palce_bankBranch:"Nhập thông tin PGD ",
		label_bankNumber:"Số tài khoản ngân hàng",
		palce_bankNumber:"Nhập chính xác số tài khoản",
		label_cardNumber: "Giấy tờ tùy thân",
		place_cardNumber: "Nhập mã số giấy tờ tùy thân",
		card_img1: "Tải ảnh mặt trước của giấy tờ",
		card_img2: "Tải ảnh mặt sau của giấy tờ",
		card_img_tip:"Thông tin bạn cung cấp sẽ được mã hóa và bảo mật, hệ thống sẽ tự động xét duyệt thông tin của bạn, hãy yên tâm xác thực.",
		label_feedbackType: "Loại câu hỏi",
		place_feedbackType: "Vui lòng chọn loại câu hỏi",
		feedbackType0: "Rút tiền",
		feedbackType1: "Câu hỏi khác",
		label_feedbackTitle: "Tiêu đề",
		place_feedbackTitle: "Vui lòng nhập tiêu đề",
		label_feedbackCont: "Mô tả",
		place_feedbackCont: "Vui lòng nhập mô tả",
		place_currency: "Vui lòng chọn đồng tiền",
		place_account: "Vui lòng chọn tài khoản",
		label_acrrency: "Tiền tệ",
		label_number: "Số lượng",
		label_record: "Hồ sơ",
		label_time: "Thời gian",
		label_available: "Có sẵn",
		label_freeze: "Đóng băng",
		label_total: "Liên kết",
		label_operate: "Hoạt động",
		label_transfer_currency: "Chuyển đổi tiền tệ",
		label_transfer_account: "Chuyển tài khoản",
		label_account_balance: "Số dư tài khoản",
		label_transfer_number: "Số lượng gạch ngang",
		place_transfer_number: "Vui lòng nhập số lượng gạch ngang",
		label_buy_price: "Giá mua",
		label_buy_number: "Khối lượng mua",
		place_buy_market: "Mua ở mức giá tốt nhất thị trường",
		label_sell_price: "Giá bán",
		label_sell_number: "Giá bán",
		place_sell_market: "Bán với giá tốt nhất thị trường",
		product_name: "Bán với giá tốt nhất thị trường",
		buy_total: "Mua tổng số lượng",
		earnings: "Thu nhập tích lũy",
		product_cycle: "Chu kỳ sản phẩm",
		state: "Trạng thái",
		time: "Thời gian",
		place_phone_email: "Nhập Email",
		login: "Đăng nhập",
		register: "Đăng ký",
		label_price: "Giá cả",
		place_price: "Vui lòng nhập giá",
		label_multiple: "Bội số",
		place_multiple: "Vui lòng chọn Multiple",
		label_hand: "Số lượng",
		place_hand: "Vui lòng nhập số tay",
		label_volume: "Lượng 24H",
		daily_fluctuations: "Ngày lên xuống",
		label_quotes: "Gần 3 ngày",
		label_toTrade: "Giao dịch",
		available_amount: "Số lượng có sẵn",
		label_transfer_psd: "Mật khẩu giao dịch",
		palce_transfer_psd: "Vui lòng nhập mật khẩu giao dịch",
		buy_long: "Mua nhiều",
		sell_short: "Bán khống",
		elail_login: "Đăng nhập tài khoản",
		phone_login: "Đăng nhập di động",
		label_email: "Email",
		place_email: "Vui lòng nhập Email",
		label_phone: "Số điện thoại",
		place_phone: "Vui lòng nhập số điện thoại",
		label_password: "Mật khẩu",
		place_password: "Vui lòng nhập mật khẩu",
		remember_psd: "Ghi nhớ mật khẩu",
		no_account: "Chưa có tài khoản",
		forget_psd: "Quên mật khẩu？",
		label_verify: "Mã xác nhận",
		place_Verify: "Vui lòng nhập CAPTCHA",
		send: "Gửi",
		label_invite: "Mã mời",
		place_invite: "Vui lòng nhập mã mời",
		have_account: "Có tài khoản？",
		go_login: "Đăng nhập",
		label_linkName: "Tên chuỗi",
		label_recharge_address: "Địa chỉ rút tiền",
		place_recharge_address: "Vui lòng nhập địa chỉ rút tiền",
		label_recharge_number: "Số tiền nạp đầy",
		place_recharge_number: "Vui lòng nhập số tiền nạp",
		label_wallet_address: "Địa chỉ ví",
		place_wallet_address: "Vui lòng nhập địa chỉ ví",
		label_withdraw_number: "Số lượng rút tiền",
		place_withdraw_number: "Vui lòng nhập số lượng rút tiền",
		place_min_withdraw: "Số lượng rút tiền tối thiểu",
		label_remark: "Ghi chú",
		place_remark: "Vui lòng nhập ghi chú",
		label_transPsd: "Mật khẩu giao dịch",
		place_transPsd: "Vui lòng nhập mật khẩu giao dịch",
		message1: "Vui lòng nhập số tiền",
		message2: "Không đủ số dư tài khoản",
		label_amount: "Số tiền",
		place_amount: "Vui lòng nhập số tiền",
	},
	table: {
		label_time: "Thời gian",
		label_title: "Cặp giao dịch",
		label_direction: "Hướng dẫn",
		label_price: "Giá cả",
		label_number: "Số lượng",
		label_volume: "Khối lượng",
		label_operate: "Hoạt động",
		label_acrrency: "Tiền tệ",
		label_last_price: "Giá mới nhất",
		label_ncrease: "Tăng",
		label_height_price: "Giá cao nhất",
		label_low_price: "Giá thấp nhất",
		label_quantity: "Lượng 24H",
		label_buyPrice: "Giá mua",
		label_finalPrice: "Giá giao dịch",
		label_expectedProfit: "Lợi nhuận và lỗ dự kiến",
		label_countDown: "Đếm ngược",
		sell: "Bán hàng",
		buy: "Mua hàng",
		label_type: "Loại",
		label_open_price: "Giá mở",
		label_now_price: "Giá hiện tại",
		label_bail: "Ký quỹ",
		label_stop_profit: "Giá dừng doanh thu",
		label_stop_loss: "Giá dừng lỗ",
		label_open_time: "Thời gian mở vị trí",
		label_fee: "Phí xử lý",
		label_overnight_fee: "Phí qua đêm",
		label_profit_loss: "Lợi nhuận và lỗ",
		label_total_amount: "Tổng số tiền",
		label_order_time: "Thời gian đặt hàng",
		label_order_income: "Thu nhập đơn hàng",
		label_state: "Trạng thái",
		label_endTime: "Thời gian kết thúc",
		state0: "Đang được xem xét",
		state1: "Đã hoàn thành",
	},
	params: {
		recordType1: "Chi phí",
		recordType2: "Nạp tiền",
		ieoState0: "Tất cả",
		ieoState1: "Đang bán",
		ieoState2: "Đang bán",
		ieoState3: "Đang bán",
		depositState0: "Đã kết thúc",
		depositState1: "Mong chờ",
		lockStateType0: "Đang chạy",
		lockStateType1: "Đang bán",
		lockStateType2: "Đã Hủy bỏ",
	},
	city: {
		albania: "Albania",
		algeria: "An- giê- riName",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Châu Úc",
		pakistan: "Việt Nam",
		austria: "Áo",
		bahrain: "Bahrain",
		belgium: "Bỉ",
		bosnia_and_Herzegovina: "Bosnia và Herzegovina",
		brazil: "Việt Nam",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Campuchia",
		canada: "Canada",
		cameroon: "Cameroon",
		chile: "Chi- lê",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croatia",
		cyprus: "Síp",
		czech_Republic: "Cộng hòa Séc",
		denmark: "Đan Mạch",
		dominican_Republic: "Cộng hòa Dominica",
		egypt: "Ai Cập",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Phần Lan",
		france: "Pháp",
		georgia: "Georgia",
		germany: "Đức",
		ghana: "Ghana",
		greece: "Hy Lạp",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hồng Kông, Trung Quốc",
		hungary: "Việt Nam",
		iceland: "Iceland",
		ireland: "Việt Nam",
		italy: "Ý",
		india: "Ấn độ",
		indonesia: "Việt Nam",
		israel: "Israel",
		iran: "Iran",
		iraq: "Iraq",
		japan: "Nhật Bản",
		kazakstan: "Cộng hòa Kazakhstan",
		kenya: "Việt Nam",
		korea: "Hàn Quốc",
		kuwait: "Kuwait",
		kyrgyzstan: "Kyrgyzstan",
		laos: "Lào",
		latvia: "Latvia",
		lithuania: "Li- tu- a- niName",
		luxembourg: "Luxembourg",
		macao_China: "Ma Cao, Trung Quốc",
		macedonia: "Ma- xê- đô- ni- aName",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Mexico",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mông Cổ",
		montenegro: "Cộng hòa Montenegro",
		morocco: "Ma- rốc",
		myanmar: "Miến Điện",
		netherlands: "Hà Lan",
		new_Zealand: "New Zealand",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Na Uy",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama.",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Philippines",
		poland: "Ba Lan",
		portugal: "Bồ Đào Nha葡萄牙",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Rumani",
		russia: "Nga",
		republic_of_Trinidad_and_Tobago: "Cộng hòa Trinidad và Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Ả Rập Saudi",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovakia",
		slovenia: "Xlô- viName",
		south_Africa: "Nam Phi",
		spain: "Tây Ban Nha",
		sri_Lanka: "Sri Lanka",
		sweden: "Thụy Điển",
		switzerland: "Thụy Sĩ",
		taiwan_China: "Đài Loan taiwan_China",
		tajikistan: "Cộng hòa Tajik",
		tanzania: "Tanzania",
		thailand: "Thái Lan",
		turkey: "Thổ Nhĩ Kỳ",
		turkmenistan: "Cộng hòa Turkmen",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Các Tiểu vương quốc Ả Rập Thống nhất",
		united_Kingdom: "United Kingdom",
		united_States: "Hoa Kỳ",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Belarus",
		belize: "Belize",
		benin: "Bénin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Quần đảo Virgin thuộc Anh",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Quần đảo Cayman",
		central_African_Republic: "Cộng hòa Trung Phi",
		chad: "Chad",
		comoros: "Comoros",
		the_Republic_of_the_Congo: "Congo (bằng tiếng Anh).",
		democratic_Republic_of_the_Congo: "Congo (Kim)",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guinea Xích đạo",
		eritrea: "Eritrea",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Đảo Man",
		cote_d_Ivoire: "Bờ Biển Ngà",
		jamaica: "Nha- mê- caName",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Li- bi- aName",
		madagascar: "Madagascar",
		malawi: "Ma- la- uy",
		maldives: "Maldives",
		mali: "Ma- li- ",
		mauritania: "Ma- ri- tanhName",
		mauritius: "Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Bắc Triều Tiên",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Thụy Sĩ",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Quần đảo Virgin thuộc Mỹ",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatican",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
		china: "Trung Quốc",
	}
};
